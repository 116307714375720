<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ name }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-row>
                <p>&nbsp;</p>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>System name as shown on screen</ion-label></ion-col>
                <ion-col size="5"><ion-input name="systemName" v-model="systemName" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row v-if="useWelcomeMessage === '1'" class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Welcome Message</ion-label></ion-col>
                <ion-col size="5"><ion-input name="welcomeMessage" v-model="welcomeMessage" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Customer Image URL</ion-label></ion-col>
                <ion-col size="5"><ion-input name="customerImage" v-model="customerImage" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>System Method</ion-label></ion-col>
                <ion-col size="5"><ion-input name="systemType" v-model="systemType" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>System Type</ion-label></ion-col>
                <ion-col size="5"><ion-input name="daysNights" v-model="daysNightsText" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Number of {{ daysNightsText }}</ion-label></ion-col>
                <ion-col size="5"><ion-input name="defaultTime" v-model="defaultTime" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Tablet Name sent to Enforcement</ion-label></ion-col>
                <ion-col size="5"><ion-input name="tabletName" v-model="tabletName" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Whitelist ID</ion-label></ion-col>
                <ion-col size="5"><ion-input name="whitelistId" v-model="whitelistId" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Seconds to wait for response</ion-label></ion-col>
                <ion-col size="5"><ion-input name="postDelay" v-model="postDelay" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row v-show="useZones === '1'" class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>How Many Sites are there?</ion-label></ion-col>
                <ion-col size="5"><ion-input name="siteNumber" v-model="siteNumber" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row v-show="useZones === '1' && parseInt(siteNumber) > 0" class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Zone Controls</ion-label></ion-col>
                <ion-col size="5">
                    <template v-for="n in parseInt(siteNumber)" :key="n">
                        <ion-row>
                            <ion-col size="6">
                                <ion-input :name="'zoneName' + n-1" v-model="zoneName[n-1]" placeholder="Zone Name" type="text" disabled></ion-input>
                            </ion-col>
                            <ion-col size="6">
                                <ion-input :name="'zoneCode' + n-1" v-model="zoneCode[n-1]" placeholder="Whitelist ID" text="text" disabled></ion-input>
                            </ion-col>
                        </ion-row>
                    </template>
                </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>Use Class?</ion-label></ion-col>
                <ion-col size="5"><ion-input name="useClass" type="text" disabled :value="(useClass === '1') ? 'Yes' : 'No'"></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="2"></ion-col>
                <ion-col size="3"><ion-label>System Brand</ion-label></ion-col>
                <ion-col size="5"><ion-input name="systemBrand" v-model="systemBrand" type="text" disabled></ion-input></ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center">
                <ion-col size="5"></ion-col>
                <ion-col size="5">
                    <ion-button expand="block" color="info" @click.prevent="saveSettings()">
                        <p>Back</p>
                    </ion-button>
                </ion-col>
                <ion-col size="2"></ion-col>
            </ion-row>
            <ion-row>
                <p>&nbsp;</p>
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {IonPage, IonLabel, IonInput, IonContent, IonHeader, IonTitle, IonToolbar} from "@ionic/vue";
import {api} from "@/main";

export default {
    name: 'Settings',
    components: { IonPage, IonLabel, IonInput, IonContent, IonHeader, IonTitle, IonToolbar },
    props: {
        name: String
    },
    data() {
        return {
            systemName: '',
            useWelcomeMessage: '',
            welcomeMessage: '',
            customerImage: '',
            systemType: '',
            daysNights: 'days',
            defaultTime: '',
            tabletName: '',
            whitelistId: '',
            postDelay: '2',
            useZones: '0',
            siteNumber: '1',
            zoneName: [],
            zoneCode: [],
            useClass: '0',
            systemBrand: 'TPS'
        }
    },
    mounted() {
        api.get('/api/settings', {
            headers: {
                Authorization: 'Bearer: ' + this.token
            }
        }).then((response) => {
            response.data.forEach((value) => {
                this[value.setting_name] = (value.setting_name === 'zoneName' || value.setting_name === 'zoneCode')
                    ? JSON.parse(value.setting_value)
                    : value.setting_value;
            })
        }).finally(() => {
            this.setSettings(this.appSettings);
        });
    },
    methods: {
        saveSettings() {
            this.disableLogin();
            this.$router.push('/tabs/home');
        },
        ...mapActions('users', {
            setSettings: 'setSettings',
            disableLogin: 'disableLogin'
        })
    },
    computed: {
        daysNightsText() {
            if (this.daysNights === '') {
                return 'Days OR Nights';
            }

            return this.daysNights.charAt(0).toUpperCase() + this.daysNights.slice(1);
        },
        ...mapGetters('users', {
            getSettings: 'settings',
            token: 'token'
        }),
        appSettings() {
            return {
                systemName: this.systemName,
                useWelcomeMessage: this.useWelcomeMessage,
                welcomeMessage: this.welcomeMessage,
                customerImage: this.customerImage,
                systemType: this.systemType,
                daysNights: this.daysNights,
                defaultTime: this.defaultTime,
                tabletName: this.tabletName,
                whitelistId: this.whitelistId,
                postDelay: this.postDelay,
                useZones: this.useZones,
                siteNumber: this.siteNumber,
                zoneName: this.zoneName,
                zoneCode: this.zoneCode,
                useClass: this.useClass,
                systemBrand: this.systemBrand
            }
        }
    }
}
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

ion-input, ion-select, select {
    width: 100%;
    padding: 10px;
    border: 2px solid #666666;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 25px;
    background-color: yellow;
    margin-top: 5px;
}

ion-button {
    margin-bottom: 3em;
}

</style>